@import url("https://fonts.googleapis.com/css2?family=Yantramanav:wght@100;300;400;500;700;900&display=swap");
:root {
  --primaryColor: #fbc112;
  --white: #ffffff;
  --linkColor: #1967d2;
  --lightBlack: #33373d;
  --success: #198754;
}

* {
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: #f3f3f3;
  min-width: 320px;
  font-size: 14px;
  line-height: 1.4285em;
  color: #33373d;
  scroll-behavior: smooth;
  font-family: "Yantramanav", sans-serif;
}

body.popup-opned {
  overflow-y: hidden;
}

body.search-fixed {
  margin-top: 180px;
}

::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(114, 114, 114);
}

.location {
  padding: 8px 12px;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
}

.location .location-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.location .location-icon {
  font-size: 15px;
}

.location span {
  font-size: 14px;
  color: #000;
  margin: 0 7px;
}

.location .arrow-icon {
  color: #cccccc;
  font-size: 18px;
}

.header-box {
  background-color: var(--primaryColor);
  padding-bottom: 5px;
}

.logo {
  display: flex;
  padding: 20px 16px;
}

.logo .logo-wrap {
  width: 10vw;
  height: 10vw;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  text-align: center;
  background-color: var(--white);
  padding: 0;
}

.logo .logo-wrap img {
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  filter: none;
  object-fit: contain;
  padding: 1px;
  max-height: 70px;
  display: block;
  margin: auto;
  max-width: 130px;
}

.logo .store-name {
  font-size: 15px;
  font-weight: 500;
  margin-left: 8px;
  color: var(--white);
}

.search-fixed .header-search {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 0 10px 0 #d9d9d9;
  animation: slideSmooth 0.3s;
}

@keyframes slideSmooth {
  0% {
    top: -65px;
  }
  100% {
    top: 0;
  }
}

.header-search {
  width: 100%;
  z-index: 2;
  top: 0;
  padding: 11px 16px;
}

.header-search span {
  border: 0.3px solid #ebebeb;
  padding: 11px 12px 10px 16px;
  width: 100%;
  border-radius: 7px;
  color: #7f7f7f;
  font-size: 16px;
  height: 43px;
  font-weight: 400;
  opacity: 1;
  display: flex;
  background: #fff;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1607843137254902);
  cursor: text;
}

.header-search span .search-icon {
  margin-right: 10px;
  margin-bottom: 1px;
  font-size: 16px;
}

.banner-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 210px;
  padding: 14px 13px 28px 18px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.banner-wrapper .banner-img-container {
  display: flex;
  justify-content: space-between;
}

.banner-wrapper .banner-img-container .banner-img {
  margin-right: 15px;
  max-width: 310px;
  min-width: 310px;
  max-height: 165px;
  min-height: 165px;
}

.banner-wrapper .banner-img-container .banner-img:last-child {
  margin-right: 10px;
}

.banner-wrapper .banner-img-container .banner-img img {
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1607843137254902);
  max-width: 310px;
  min-width: 310px;
  max-height: 165px;
  min-height: 165px;
}

.banner-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.offer-wrap {
  width: 100%;
  background-color: var(--white);
  padding: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: -10px;
}

.offer-wrap .offer-banner {
  min-height: 132px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.0784313725490196);
  position: relative;
  display: flex;
  align-items: flex-start;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #f2d5ba;
  overflow: hidden;
  padding: 20px 8px 20px 20px;
  border-radius: 8px;
}

.offer-wrap .offer-banner .offer-text {
  color: #000;
  font-weight: 700;
  width: 62%;
  display: flex;
  flex-direction: column;
  align-self: center;
  line-height: 1.42;
  min-height: 88px;
  justify-content: space-between;
}

.offer-wrap .offer-ttl {
  color: #3e2a06;
  font-size: 19px;
  font-weight: 600;
}

.offer-wrap .offer-view {
  color: #3e2a06;
  font-size: 13px;
  font-weight: 600;
  margin-top: 12px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}

.offer-wrap .offer-icon {
  font-size: 19px;
  margin-left: 6px;
  margin-top: -3px;
}

.footer {
  background: #fafafa 0 0 no-repeat padding-box;
  padding: 28px 16px 32px;
  color: #000;
  padding-bottom: 120px;
  margin-top: 20px;
}

.footer .footer-top {
  padding-bottom: 32px;
}

.footer .foot-store {
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 16px;
}

.foot-list-wrap {
  display: flex;
  padding-top: 16px;
}

.foot-icon {
  font-size: 18px;
}

.foot-list-wrap span,
.foot-list-wrap a,
.footer .footer-bottom span {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 500;
}

.foot-list-wrap span,
.foot-list-wrap a,
.footer .footer-bottom div.cprt {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 500;
}

.footer a {
  color: var(--linkColor);
  text-decoration: none;
}

.footer .footer-bottom {
  border-top: 1px solid #f0f0f0;
  padding-top: 32px;
  text-align: center;
}

.nav-menu-wrapper .menu-view-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  font-size: 13px;
  font-weight: 900;
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  border: 2px solid var(--white);
  background-color: var(--primaryColor);
  color: var(--white);
  z-index: 2;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.nav-menu-wrapper .menu-view-icon.bottom-up {
  bottom: 100px;
}

.nav-menu-wrapper .menu-view-icon .burger {
  font-size: 16px;
}

.bottom-card-row {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.1607843137254902);
  z-index: 12;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}

.cart-bar {
  width: 100%;
  padding: 7px 16px;
  background-color: var(--primaryColor);
  color: #fff;
  display: block;
}

.cart-bar .cart-items-bottom-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-bar .cart-items-bottom-bar .cart-shopping-bag {
  position: relative;
  display: flex;
  align-items: center;
}

.cart-bar .cart-items-bottom-bar .cart-shopping-bag .cart-icon {
  margin-right: 16px;
  font-size: 27px;
}

.cart-bar .cart-items-bottom-bar .cart-shopping-bag .cart-amt {
  font-size: 16px;
  font-weight: 600;
}

.cart-bar .cart-items-bottom-bar .cart-shopping-bag .total-items {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #d91b12;
  text-align: center;
  position: absolute;
  left: 15px;
  top: -2px;
  font-size: 12px;
  line-height: 22px;
  font-weight: 700;
  color: var(--white);
}

.cart-bar .cart-items-bottom-bar .cart-bottom-button {
  display: flex;
  align-items: center;
  padding: 9px 4px;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
}

.cart-bar .cart-items-bottom-bar .cart-bottom-button span {
  margin-right: 5px;
}

.cart-bar .cart-items-bottom-bar .cart-bottom-button .cart-arrow-icon {
  font-size: 22px;
}

.productlist-wrap .category-wrap {
  background-color: var(--white);
  padding: 30px 16px;
  margin-top: 20px;
}

.search-bar-wrap .productlist-wrap .category-wrap {
  padding: 10px 0px;
}

.productlist-wrap .category-wrap .c-ttl {
  background: #fff;
  padding-bottom: 10px;
  font-size: 26px;
  line-height: 25px;
  font-weight: 700;
  color: #202431;
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.productlist-wrap .product-item {
  border: none;
  border-bottom: 2px solid #f3f6fa;
  padding: 16px 0 8px;
  display: flex;
  justify-content: space-between;
  border-radius: 0;
}

.productlist-wrap .product-item .p-name {
  font-size: 18px;
  display: block;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.5px;
  color: #000;
}

.productlist-wrap .product-item .price,
.product-detail .price {
  font-size: 17px;
  font-weight: 500;
  color: #585858;
  margin-bottom: 30px;
}

.productlist-wrap .product-item .price span,
.product-detail .price span {
  display: block;
  text-decoration: line-through;
  font-size: 15px;
  font-weight: 400;
}

.productlist-wrap .product-item .img-box {
  border-radius: 6px;
  height: 100px;
  width: 124px;
  background-position: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.productlist-wrap .product-item .img-box img {
  object-fit: contain;
  max-height: 100%;
  width: 100%;
  border-radius: 6px;
}

.productlist-wrap .product-item .btn-div,
.product-detail .btn-div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  min-width: 0;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: 1px solid #e7eced;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12156862745098039);
  position: absolute;
  right: 18px;
  top: 92px;
  width: 88px;
  height: 36px;
  z-index: 1;
  background-color: #fff;
  border-radius: 6px;
  color: var(--primaryColor);
  user-select: none;
}

.productlist-wrap .product-item .without-img .btn-div,
.productlist-wrap .product-item .without-img .increment-btn {
  position: relative;
  top: 10px;
}

.productlist-wrap .product-item .p-left {
  position: relative;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.order-screen .productlist-wrap .product-item .p-left {
  width: calc(100% - 110px);
}

.productlist-wrap .product-item .p-right {
  position: relative;
  margin-bottom: 30px;
}

.productlist-wrap .product-item .sku {
  position: absolute;
  right: 0;
  bottom: 30px;
  color: grey;
  width: 150px;
  text-align: right;
}

.productlist-wrap .product-item .increment-btn,
.product-detail .increment-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  min-width: 0;
  font-size: 16px;
  font-weight: 700;
  border: 1px solid #e7eced;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12156862745098039);
  position: absolute;
  right: 18px;
  top: 92px;
  width: 88px;
  height: 36px;
  z-index: 1;
  background-color: #fff;
  border-radius: 6px;
  color: var(--primaryColor);
  user-select: none;
}

.increment-btn .add-loader-slider {
  position: absolute;
  bottom: 0;
  width: 15px;
  height: 3px;
  animation: slideAdd 1s infinite;
  background: var(--primaryColor);
}

.increment-btn .remove-loader-slider {
  position: absolute;
  bottom: 0;
  width: 15px;
  height: 3px;
  animation: slideRemove 1s infinite;
  background: var(--primaryColor);
}

@keyframes slideAdd {
  0% {
    right: 10%;
  }

  50% {
    right: 70%;
  }

  100% {
    right: 10%;
  }
}

@keyframes slideRemove {
  0% {
    left: 10%;
  }

  50% {
    left: 70%;
  }

  100% {
    left: 10%;
  }
}

.productlist-wrap .product-item .increment-btn div,
.product-detail .increment-btn div {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 700;
}

.productlist-wrap .product-item .increment-btn .add-icon,
.product-detail .increment-btn .add-icon {
  width: 22px;
  font-size: 12px;
  cursor: pointer;
}

.productlist-wrap .product-item .increment-btn .p-qty,
.product-detail .increment-btn .p-qty {
  flex: 1 1;
  word-wrap: normal;
  border-radius: 0;
}

.search-bar-wrap {
  background: #fff;
}

.search-bar-wrap .search-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  background: #fff;
  padding: 12px 16px;
}

.search-bar-wrap .search-bar-input {
  position: relative;
}

.search-bar-wrap .search-bar .search-arw {
  font-size: 24px;
  justify-content: center;
}

.search-bar-wrap .search-bar .icon-box {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  height: 24px;
}

.search-bar-wrap .search-bar .icon-box span {
  display: none;
}

.search-bar-wrap .search-bar input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 14.5px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 12px 42px;
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 0 0 8px #e6e6e6;
  border-radius: 6px;
  text-decoration: none;
  min-height: 43px;
  outline: none;
  border: none;
}

.search-bar-wrap .search-bar input:focus {
  color: #000;
}

.search-bar-wrap .search-bar .search-close-icon {
  font-size: 17px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #a2a5a8;
  cursor: pointer;
}

.menu-card {
  width: 100%;
  padding: 70px 16px 120px;
  min-height: 93vh !important;
}

.popular-search .ps-ttl {
  font-size: 18px;
  font-weight: 700;
  padding-top: 13px;
  padding-bottom: 10px;
}

.popular-search .ps-icon {
  font-size: 15px;
  margin-right: 5px;
}

.popular-search .popular-icon-chip {
  display: inline-block;
  padding: 4px 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-transform: capitalize;
  background: #f7f8fa 0 0 no-repeat padding-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08235294117647059);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.search-bar-wrap .action-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 16px;
  background-color: #fff;
  z-index: 1;
  display: none;
}

.search-bar-wrap .back-btn {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  padding: 14px;
  height: auto;
  border-radius: 12px;
  text-align: center;
  color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  background-color: var(--white);
  cursor: pointer;
}

.order-screen {
  color: #000;
  display: block;
  width: 100%;
  font-weight: 600;
  /* height: calc(100vh + 1px);
  overflow-y: scroll; */
  padding: 0;
  background: #f5f5f5;
  padding-bottom: 98px;
}

.order-screen .header-title {
  display: flex;
  align-items: center;
  padding: 0 15px 0 24px;
  position: sticky;
  position: -webkit-sticky;
  background: #fff;
  width: 100%;
  top: 0;
  z-index: 11;
  left: 0;
  height: 56px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08235294117647059);
  font-size: 20px;
  font-weight: 600;
}

.order-screen .header-title .order-arw {
  font-size: 24px;
  font-weight: 500;
  margin-right: 7px;
  cursor: pointer;
}

.user-list {
  background: #fff;
  display: flex;
  justify-content: flex-start;
  padding: 16px 15px 12px;
}

.user-list .highlight-box {
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  background-color: #f8f8f8;
  margin-right: 12px;
}

.user-list .cart-bold-type {
  color: #1a1a1a;
  font-weight: 700;
  font-size: 17px;
  letter-spacing: -0.2px;
  margin-bottom: 2px;
}

.user-list .cart-regular-type {
  color: #919191;
  font-weight: 400;
  font-size: 15px;
  display: block;
  letter-spacing: -0.2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-list .edit-phone-btn {
  margin-left: auto;
}

.cart-change {
  color: var(--primaryColor);
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  display: block;
}

.order-screen .user-info {
  padding: 15px 0;
  background-color: var(--white);
}

.cart-amount .cart-price-details,
.cart-item .cart-item-details {
  padding: 24px 15px;
  background-color: var(--white);
  margin-top: 15px;
}

.cart-price-heading,
.cart-item-heading {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}

.cart-amount .cart-price-details .cart-tax {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 400;
  color: #4f4f4f;
  padding: 0 0 12px;
  letter-spacing: 0.33px;
}

.cart-amount .cart-total-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid #e8e8e8;
  font-weight: 700;
  margin-top: 8px;
}

.cart-amount .cart-total-amount span {
  font-size: 20px;
  color: #000;
  padding: 0;
  letter-spacing: 0.44px;
}

.order-screen .productlist-wrap .product-item .increment-btn,
.order-screen .productlist-wrap .product-item .btn-div,
.product-detail .btn-div,
.product-detail .increment-btn {
  position: relative;
  top: 0;
  right: 0;
  user-select: none;
}

.order-screen .productlist-wrap .product-item .img-box {
  display: none;
}

.order-screen .productlist-wrap .product-item .price span,
.product-detail .price span {
  display: inline-block;
  margin-right: 5px;
}

.popup-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 13;
  background: #000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  animation: opacityFade 0.1s forwards alternate linear;
}

.bottom-popup .popup-body {
  overflow: visible;
  border: 0 solid var(--primaryColor);
  min-height: 0;
  position: fixed;
  z-index: 14;
  background: var(--white) 0 0 no-repeat padding-box;
  font-size: 12px;
  font-weight: 900;
  visibility: visible;
  background-color: #fff;
  transition: all 0.5s ease-out;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  height: auto;
  bottom: 0;
  right: 0;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  animation: slideTop 0.2s forwards alternate linear;
}

@keyframes slideTop {
  0% {
    transform: translateY(500px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes opacityFade {
  0% {
    opacity: 0;
  }
  100% {
    transform: 1;
  }
}

.order-screen .pay-button-wrap {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #fff;
  padding: 15px;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.0392156862745098);
  z-index: 1;
}

.order-screen .primary-cta {
  width: 100%;
  background: var(--success);
  color: var(--white);
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  height: auto;
  border: none;
  padding: 14px;
  border-radius: 10px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.order-screen .primary-cta[disabled] {
  opacity: 0.5;
}

.order-screen .primary-cta .cta-text {
  text-transform: uppercase;
  font-size: 16px;
}

.page-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #fff;
}

.loader {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  background: radial-gradient(circle closest-side, #000 94%, #0000)
    right/calc(200% - 1em) 100%;
  animation: l24 1s infinite alternate linear;
}

.loader::before {
  content: "Loading...";
  line-height: 1em;
  color: #0000;
  background: inherit;
  background-image: radial-gradient(circle closest-side, #fff 94%, #000);
  -webkit-background-clip: text;
  background-clip: text;
}

@keyframes l24 {
  100% {
    background-position: left;
  }
}

.product-detail .img {
  max-height: 65vh;
  position: relative;
  overflow: hidden;
}

.product-detail .img img {
  width: 100%;
  border-radius: 8px 8px 0 0;
  object-fit: cover;
  border: 0;
  vertical-align: top;
}

.product-detail .top {
  padding: 25px 16px;
  text-align: left;
  position: relative;
}
.product-detail .top .sku {
  position: absolute;
  top: 4px;
  right: 19px;
  color: grey;
}

.product-detail .bot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-top: 1px solid #d5d9de;
}

.product-detail .p-name {
  font-size: 19px;
  font-weight: 700;
  color: #2a2a2a;
}

.product-detail .price {
  margin-bottom: 0;
}

.popup-body .close-top {
  position: absolute;
  top: -17px;
  right: 16px;
  z-index: 1;
  color: #000;
  font-size: 34px;
  border: 1px solid var(--white);
  border-radius: 50%;
  background: var(--white);
  cursor: pointer;
}

.menu-popup {
  border-top-left-radius: 400px;
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-top: 10px;
  color: #000;
  position: relative;
}

.menu-popup .menu-list-wrapper {
  margin-bottom: 60px;
  margin-left: 15%;
  width: 80%;
}

.menu-popup .menu-list-wrapper.fade:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 60px;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff 80%);
  width: 90%;
  height: 8em;
}

.menu-popup .menu-list {
  list-style: none;
  padding: 0;
  max-height: 300px;
  min-height: 100px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  width: 90%;
  margin-bottom: 0;
}

.menu-popup .menu-list li {
  letter-spacing: 0.5px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  max-width: 100%;
  position: relative;
  margin-left: 0;
  margin-right: auto;
  border-bottom: 0.5px solid #efefef;
  padding: 14px 0;
  cursor: pointer;
}

.menu-popup .fade .menu-list li:last-child {
  margin-bottom: 50px;
}

.menu-popup .menu-close-icon,
.loc-popup .loc-close-icon {
  position: fixed;
  bottom: 30px;
  right: 20px;
  font-size: 32px;
  z-index: 1;
  cursor: pointer;
}

.offer-popup .offer-banner {
  height: 92px;
  background: transparent linear-gradient(212deg, #f9d78a, #dda348) 0 0
    no-repeat padding-box;
  padding: 34px 20px;
  color: #3e2a06;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  border-radius: 12px 12px 0 0;
  align-items: center;
  text-align: left;
}

.offer-popup .offer-banner .offer-arw {
  font-size: 30px;
  margin-right: 8px;
  cursor: pointer;
}

.offer-popup .offer-banner .head {
  width: 65%;
  line-height: 1.42;
  font-size: 19.5px;
  color: #202431;
  font-weight: unset;
}

.offer-popup .offer-content {
  height: calc(55vh - 65px);
  overflow-y: auto;
  padding: 0 20px;
}

.offer-popup .offer-content-li {
  display: flex;
  flex-direction: column;
  padding: 20px 0 8px;
  border-bottom: 0.5px solid #e9ecf2;
}

.offer-popup .offer-content-li .ttl {
  color: #000;
  font-size: 17px;
  font-weight: 700;
  padding-right: 20px;
  text-overflow: ellipsis;
  letter-spacing: 0.61px;
  display: -webkit-box;
}

.offer-popup .offer-content-li .desc {
  color: #a5a2b2;
  font-size: 13px;
  font-weight: 400;
}

.productlist-wrap .not-found-text {
  font-size: 22px;
  padding: 22px 0 40px;
  line-height: 28px;
  color: #202431;
  font-weight: 700;
}

.contact-popup {
  background: #fff;
  padding: 20px;
  bottom: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 100%;
  overflow-y: auto;
  max-height: 570px;
}

.contact-popup h2 {
  font-size: 24px;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
}

.contact-popup .small-heading {
  color: #9ea6b9;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 15px;
  display: block;
}

.contact-popup .form-group {
  margin-top: 10px;
  position: relative;
  margin-bottom: 16px;
}

.contact-popup .form-group .form-control-sel-opt,
.loc-popup input {
  background-color: #f6f6f6;
  border: 1px solid #d3d3d3;
  border-radius: 12px;
  padding: 15px 15px 15px 15px;
  padding-left: 15px;
  background: #f6f6f6 0 0 no-repeat padding-box;
  color: var(--primaryColor);
  font-size: 16.5px;
  font-weight: 500;
  line-height: 1.5;
  display: block;
  width: 100%;
  outline: none;
}

.contact-popup .form-group .form-control,
.loc-popup input {
  background-color: #f6f6f6;
  border: 1px solid #d3d3d3;
  border-radius: 12px;
  padding: 25px 25px 25px 40px;
  padding-left: 15px;
  background: #f6f6f6 0 0 no-repeat padding-box;
  color: var(--primaryColor);
  font-size: 16.5px;
  font-weight: 500;
  line-height: 1.5;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  outline: none;
}

.contact-popup .bottom-txt {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 30px;
  display: block;
  line-height: 16px;
  color: #9ea6b9;
}

.contact-popup button {
  background-color: var(--primaryColor);
  color: var(--white);
  border: 0;
  width: 100%;
  padding: 20px;
  font-size: 17px;
  font-weight: 600;
  border-radius: 12px;
  cursor: pointer;
}

.input-error {
  margin: 5px;
  color: red;
  font-size: 16px;
  font-weight: 500;
}

.contact-popup button[disabled] {
  opacity: 0.5;
}

.loc-popup {
  padding: 12px 16px;
  margin-top: 10px;
  color: #000;
  position: relative;
}

.loc-popup .loc-search {
  position: relative;
}

.loc-popup .loc-search input {
  padding-left: 45px;
  padding-right: 45px;
}

.loc-popup .loc-search .loc-icon {
  font-size: 24px;
  justify-content: center;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--primaryColor);
}

.loc-popup .loc-search .loc-search-close-icon {
  font-size: 17px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #a2a5a8;
  cursor: pointer;
}

.loc-popup .loc-dropdown {
  margin-bottom: 60px;
  height: 300px;
  overflow-y: auto;
  padding: 0 15px;
}

.loc-popup .loc-dropdown .loc-list {
  letter-spacing: 0.5px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  max-width: 100%;
  position: relative;
  margin-left: 0;
  margin-right: auto;
  border-bottom: 0.5px solid #efefef;
  padding: 14px 0;
  cursor: pointer;
}

.success-popup {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 13;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-popup .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  background: #000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  animation: opacityFade 0.1s forwards alternate linear;
}

.success-popup .popup-box {
  width: 300px;
  background-color: var(--white);
  border-radius: 8px;
  text-align: center;
  overflow: hidden;
  animation: slideTop 0.2s forwards alternate linear;
}

.success-popup .tic-box {
  background-color: #198754;
  color: var(--white);
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.success-popup .tic-icon {
  font-size: 60px;
}

.success-popup .tic-box span {
  text-transform: uppercase;
  font-size: 15px;
  margin-top: 5px;
  letter-spacing: 1.5px;
  opacity: 0.8;
}

.success-popup .content-box {
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 25px;
}

.success-popup .content-box img{
  max-height: 230px;
  margin-bottom: 25px;
}

.success-popup .content-box .msg {
  font-size: 17px;
  margin-bottom: 20px;
  letter-spacing: 0.5px;
  color: grey;
}

.success-popup .content-box button {
  background-color: #198754;
  color: var(--white);
  padding: 10px 25px;
  border-radius: 20px;
  border: none;
  outline: none;
  font-size: 15px;
  letter-spacing: 0.5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}

.error-alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 12;
  margin: 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  height: 85px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  animation: slideSmooth 0.3s;
}

.error-alert .left-icon {
  width: 60px;
  height: 85px;
  background-color: #dc3545;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 33px;
  color: #fff;
}

.error-alert .right-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 0 10px;
}

.error-alert .right-content .text {
  font-size: 14px;
}

.error-alert .right-content .text span {
  font-size: 16px;
  display: block;
  font-weight: 600;
}

.error-alert .right-content .icon {
  opacity: 0.6;
  cursor: pointer;
}

.order-container .oreder-main-box .right-box button,
.search-bar-wrap .search-icon {
  display: none;
}

input[type="number"].hideControls {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.banner-text-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 -5px;
  margin-top: 35px;
}

.banner-text-wrap .offer-wrap {
  width: 100%;
  margin-top: 0;
  margin-left: 5px;
  margin-right: 5px;
}

.banner-text-outer {
  width: 100%;
}

.banner-text {
  background-color: var(--primaryColor);
  color: #fff;
  border-radius: 8px;
  padding: 20px;
  font-size: 16px;
  margin-left: 5px;
  margin-right: 5px;
}

.term-btn{
  position: fixed;
  bottom: 100px;
  right: 20px;
  background: #1640D6;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 2;
  transition: all 0.5s ease;
}

.term-btn .term-plus{
  font-size: 35px;
  color: #fff;
}

.term-popup {
  padding: 15px;
  color: #000;
}

.term-popup h2 {
  font-size: 24px;
  margin: 10px 0;
  font-weight: 500;
  line-height: 1.2;
}

.term-popup .detail{
  font-size: 15px;
  font-weight: 500;
  height: 60vh;
  overflow: auto;
  padding-right: 5px;
}

.qr-sec{
  background-color: var(--white);
  margin-top: 15px;
}

.qr-sec .qr-detail{
  margin-top: 20px;
  padding: 16px;
}

.qr-sec .qr-text{
  font-weight: 400;
}

.qr-sec img{
  max-height: 250px;
}

@media (min-width: 567px) {
  .banner-text {
    margin-top: 38px;
  }
  body.search-fixed {
    margin-top: 0;
  }
  .header .header-box {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e2e2e2;
    border-top: 6px solid var(--primaryColor);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    background-color: var(--white) !important;
  }

  .header-box .header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo .logo-wrap {
    width: 56px;
    max-width: 80px;
    max-height: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .main-wrap .productlist-wrap .category-wrap .c-ttl {
    font-size: 22px;
  }

  .main-wrap .productlist-wrap .product-item .p-name {
    font-size: 17px;
  }

  .logo .logo-wrap img {
    max-width: 100%;
    max-height: 100%;
  }

  .logo .store-name {
    font-size: 18px;
    font-weight: 500;
    margin-left: 15px;
    margin-top: 10px;
    color: var(--lightBlack);
  }

  .search-fixed .header-search {
    position: relative;
    animation: none;
    box-shadow: none;
    background-color: transparent;
  }

  .header-search,
  .logo {
    padding: 0;
    flex: 1;
  }
  .header .banner-wrapper,
  .header .offer-wrap {
    display: none;
  }

  .location {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgb(245 245 245 / var(--tw-border-opacity));
    border-width: 1px;
    border-style: solid;
    padding: 16px 24px 16px 20px;
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    justify-content: flex-start;
    border-radius: 8px;
  }

  .location .loc-label {
    width: auto;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    margin-right: 10px;
    font-weight: 700;
    font-size: 18px;
  }

  .location .location-icon {
    margin-right: 5px;
  }

  .location .addr {
    width: auto;
    white-space: pre;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 18px;
  }

  .location .change-loc {
    font-size: 18px;
    color: var(--primaryColor);
    margin-left: auto;
    cursor: pointer;
    font-weight: 500;
  }

  .banner-slide {
    margin: 0 -10px;
    width: calc(100vw - 15px);
  }

  .banner-slide img {
    width: 100%;
    border-radius: 12px;
  }

  .banner-slide .banner-img {
    margin: 10px;
  }

  .banner-slide .react-multiple-carousel__arrow--right {
    right: 15px;
  }

  .banner-slide .react-multiple-carousel__arrow--left {
    left: 15px;
  }

  .banner-slide .react-multiple-carousel__arrow,
  .banner-slide .react-multiple-carousel__arrow:hover {
    z-index: 1;
    background-color: var(--white);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.0784313725490196);
    color: #202431;
  }

  .banner-slide .react-multiple-carousel__arrow::before,
  .banner-slide .react-multiple-carousel__arrow:hover::before {
    color: #202431;
    font-weight: 800;
  }

  .offer-wrap {
    padding: 0;
    margin-top: 35px;
  }

  .offer-wrap .offer-head {
    color: #202431;
    font-size: 23px;
    margin-bottom: 8px;
    padding-left: 0;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .offer-wrap .offer-banner {
    width: 100%;
  }

  .bottom-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 14;
    top: 0;
    left: 0;
  }

  .bottom-popup .popup-body {
    position: relative;
    width: 500px;
    border-radius: 20px;
    overflow: hidden;
  }

  .product-detail .img {
    height: 350px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;
    border-radius: 20px;
  }

  .product-detail .img img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border: 0;
    vertical-align: top;
  }

  .popup-body .close-top {
    top: 15px;
    right: 15px;
  }

  .loc-popup h2 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 12px;
  }

  .contact-popup {
    border-radius: 20px;
  }

  .loc-popup .loc-dropdown {
    margin-bottom: 0;
  }

  .search-bar-wrap .action-container {
    display: none;
  }

  .main-wrap,
  .main-search-wrap {
    margin-top: 94px;
    background: #fff;
    display: flex;
    flex-direction: row;
    padding: 0 15px;
    width: 100%;
    justify-content: center;
    padding-top: 20px;
  }

  .main-search-wrap {
    margin-top: 67px;
  }

  .menu-card {
    padding: 0px 16px 120px;
  }

  .main-wrap .productlist-wrap .category-wrap {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 769px) {
  .nav-menu-wrapper .menu-view-icon {
    display: none;
  }
  .banner-slide {
    width: 100%;
  }

  .header .header-box {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 23px;
    padding-bottom: 23px;
    height: 108px;
  }

  .floating-cart .cart-bar {
    padding: 8px 30px;
  }

  /* .header .header-box .header-inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1246px;
    min-width: 864px;
  } */

  .logo .store-name {
    font-size: 22px;
  }

  .header-search {
    max-width: 400px;
    min-width: 400px;
  }

  .logo .store-name {
    font-size: 25px;
  }

  .main-wrap,
  .main-search-wrap {
    margin-top: 108px;
    padding-top: 0;
    padding: 0 30px;
    width: 100vw;
  }

  .main-search-wrap {
    margin-top: 82px;
  }

  .nav-menu-wrapper,
  .main-search-wrap .left-dummy {
    border-right: 2px solid #f4f4f4;
    width: 240px;
  }

  .nav-menu-wrapper .menu-inner {
    top: 155px;
    max-height: calc(100vh - 195px);
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    padding: 15px 22px 15px 0;
    border-right: 2px solid #f4f4f4;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 240px;
    position: -webkit-sticky;
    position: sticky;
  }

  .nav-menu-wrapper .menu-inner .menu-head {
    color: #202431;
    font-size: 23px;
    margin-bottom: 8px;
    padding-left: 8px;
    font-weight: 700;
  }

  .nav-menu-wrapper .menu-inner .menu-list {
    padding-left: 0;
    list-style-type: none;
    width: 195px;
    height: 100vh;
    overflow-y: auto;
  }
  .nav-menu-wrapper .menu-inner .menu-list .menu-li {
    color: #696f76;
    font-size: 17px;
    font-weight: 400;
    padding-top: 12px;
    padding-bottom: 8px;
    padding-left: 8px;
    width: 90%;
    word-break: break-word;
    cursor: pointer;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(72 72 72 / var(--tw-text-opacity));
    position: relative;
  }

  .nav-menu-wrapper .menu-inner .menu-list .menu-li.active {
    color: var(--primaryColor);
    font-weight: 700;
  }

  .nav-menu-wrapper .menu-inner .menu-list .menu-li.active .bar {
    border-left: 3px solid var(--primaryColor);
    position: absolute;
    height: 20px;
    top: 11px;
    left: 0;
  }

  .main-wrap .right-wrap,
  .main-search-wrap .menu-card {
    width: 100%;
    margin-top: 60px;
    margin-left: 60px;
    overflow: hidden;
    height: 100%;
    margin-left: 41px;
  }

  .main-search-wrap .menu-card {
    padding: 0;
    padding-bottom: 120px;
    margin-top: 30px;
  }

  .search-bar-wrap .search-bar {
    padding: 20px 10%;
    width: 100vw;
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search-bar-wrap .search-bar-input {
    width: 500px;
  }
  .search-bar-wrap .search-bar .icon-box {
    position: absolute;
    left: -160px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .search-bar-wrap .search-bar .icon-box span {
    display: block;
    font-size: 17px;
    cursor: pointer;
    color: #202431;
    margin-left: 5px;
    font-weight: 500;
  }

  .search-bar-wrap .search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    display: block;
  }
}

@media (min-width: 900px) {
  .banner-text-wrap {
    flex-wrap: nowrap;
  }

  .banner-text-wrap .offer-wrap {
    width: 50%;
  }

  .banner-text-outer {
    width: 50%;
  }

  .banner-text {
    min-height: 134px;
  }

  .floating-cart .cart-bar {
    padding: 8px 10%;
  }

  .order-screen .header-title {
    padding-left: calc(50% - 545px) !important;
  }

  .order-container .oreder-main-box {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: flex-start;
  }

  .order-container .oreder-main-box .left-box {
    width: 640px;
    margin-right: 24px;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.050980392156862744);
  }

  .order-container .oreder-main-box .right-box {
    width: 428px;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--white);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.050980392156862744);
  }

  .order-container .oreder-main-box .right-box button {
    display: flex;
    margin: 15px auto;
    width: calc(100% - 30px);
    justify-content: center;
  }

  .order-container .oreder-main-box .right-box button .pay-amount {
    display: none;
  }

  .cart-amount .cart-price-details,
  .cart-item .cart-item-details {
    margin-top: 0;
  }

  .order-container .phone-number-text {
    display: flex;
  }

  .user-list {
    align-items: center;
  }

  .user-list .cart-bold-type {
    margin-right: 4px;
    font-weight: 500;
    font-size: 18px;
  }

  .user-list .cart-regular-type {
    font-size: 17px;
  }

  .order-screen .user-info {
    border-bottom: 1px solid #e2e2e2;
  }

  .cart-price-heading,
  .cart-item-heading {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .order-container .productlist-wrap .product-item .p-name {
    font-size: 16px;
  }

  .order-screen .pay-button-wrap {
    display: none;
  }

  .header .header-box .header-inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 1246px;
    min-width: 864px;
  }

  .main-wrap .right-wrap,
  .main-search-wrap .menu-card {
    margin-left: 60px;
    min-width: 530px;
    max-width: 948px;
  }

  .main-wrap .productlist-wrap .category-wrap {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    column-gap: 56px;
  }

  .main-wrap .productlist-wrap .product-item {
    width: calc(50% - 28px);
  }

  .floating-cart {
    cursor: default;
    box-shadow: none;
    border-top: 1px solid #e2e2e2;
    position: fixed;
    bottom: 0;
    left: 15px;
    width: 100%;
    background: var(--white);
    box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.1607843137254902);
    z-index: 2;
  }

  .floating-cart .cart-bar {
    padding: 8px 15px;
    background-color: var(--white) !important;
    color: #000;
  }

  .floating-cart .cart-items-bottom-bar {
    padding-left: 280px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1250px;
    min-width: 854px;
    width: auto;
  }

  .floating-cart .cart-bar .cart-items-bottom-bar .cart-bottom-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 4px;
    font-weight: 700;
    font-size: 17px;
    background-color: var(--primaryColor);
    color: var(--white);
    width: 200px;
    height: 40px;
    border-radius: 6px;
    cursor: pointer;
  }

  .floating-cart
    .cart-bar
    .cart-items-bottom-bar
    .cart-bottom-button
    .cart-arrow-icon {
    font-size: 25px;
  }

  .floating-cart .bottom-card-row {
    cursor: default;
    box-shadow: none;
    border-top: 1px solid #e2e2e2;
  }

  .floating-cart .cart-arw {
    font-size: 16px;
    color: gray;
    margin-left: 8px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .floating-cart .cart-arw.rotate {
    transform: rotate(180deg);
  }

  .floating-cart .cart-item {
    background-color: var(--white);
    border: 1px solid #e2e2e2;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    position: absolute;
    left: -280px;
    bottom: 45px;
    width: 340px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1607843137254902);
    animation: slideTop 0.2s forwards alternate linear;
    overflow: hidden;
  }

  .floating-cart .cart-item .productlist-wrap {
    height: 300px;
    overflow-y: auto;
    padding: 5px;
  }

  .floating-cart .cart-item .productlist-wrap .product-item .img-box {
    display: none;
  }

  .floating-cart .cart-item .cart-item-details {
    padding: 15px;
  }

  .floating-cart .cart-item .productlist-wrap .product-item .increment-btn,
  .floating-cart .cart-item .productlist-wrap .product-item .btn-div {
    position: relative;
    top: 0;
    right: 0;
    -webkit-user-select: none;
    user-select: none;
    height: 30px;
  }

  .floating-cart .cart-item .productlist-wrap .product-item .price span {
    display: inline-block;
    margin-right: 5px;
  }

  .floating-cart .cart-item .productlist-wrap .product-item .p-name {
    font-size: 16px;
  }

  .floating-cart .cart-item .productlist-wrap .product-item .price {
    font-size: 15px;
    margin-bottom: 15px;
  }

  .floating-cart .cart-item .productlist-wrap .product-item .price span {
    font-size: 13px;
  }

  .floating-cart .cart-item .productlist-wrap .sku {
    display: none;
  }

  .floating-cart .cart-item .productlist-wrap .product-item .p-right {
    margin-bottom: 15px;
  }

  .floating-cart .cart-item-heading {
    color: #202431;
    font-size: 23px;
    margin-bottom: 3px;
    padding-left: 5px;
    font-weight: 700;
  }
}

@media (min-width: 1200px) {
  .search-bar-wrap .search-bar-input {
    width: 500px;
    margin-left: -240px;
  }

  .main-wrap,
  .main-search-wrap {
    padding: 0 10%;
  }

  .header .header-box {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media (min-width: 1450px) {
  .floating-cart .cart-item {
    left: -392px;
  }
}

@media (max-width: 567px) {
  .main-wrap .location,
  .offer-wrap .offer-head,
  .loc-popup h2 {
    display: none;
  }

  .banner-text-outer {
    background-color: #fff;
    background-color: var(--white);
    padding: 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-top: -10px;
  }
}

@media (max-width: 768px) {
  .nav-menu-wrapper .menu-inner {
    display: none;
  }

  .term-btn.bottom-up{
    bottom: 175px;
  }
}
